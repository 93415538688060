<template>
    <v-toolbar
        tag="div"
        height="48"
        :style="style"
        flat
    >
        <v-container
            v-if="membershipObj"
            class="max-container"
            fluid
        >
            <div
                class="d-flex align-center flex-grow-0"
            >
                <v-avatar
                    v-if="membershipObj.brand.logo"
                    color="white"
                    size="36"
                    class="mr-4"
                    rounded
                >
                    <img
                        v-if="membershipObj.brand.logo.logoUrl"
                        :aspect-ratio="membershipObj.brand.logo.aspectRatio"
                        :src="membershipObj.brand.logo.logoUrl"
                        alt="Organization Logo"
                    />
                    <v-icon v-else :icon="mdiAccountGroup"></v-icon>
                </v-avatar>

                <h2
                    class="flex-grow-1 text-truncate text-h6"
                    :title="membershipObj.entity"
                >
                    {{ membershipObj.entity }}
                </h2>
                <div
                    v-if="membershipObj.permissions.bulkOrders"
                    class="d-flex text-body-2 ml-4 align-center flex-column"
                >
                    <v-btn
                        to="/account/bulk-order"
                        color="on-secondary"
                        variant="tonal"
                        size="small"
                        nuxt
                    >
                        Start New Order
                    </v-btn>
                </div>
                <div
                    v-if="isReseller"
                    class="d-flex text-body-2 ml-4 align-center flex-column"
                >
                    <v-btn
                        to="/account"
                        color="on-secondary"
                        variant="tonal"
                        size="small"
                        nuxt
                    >
                        Wholesale Portal
                    </v-btn>
                </div>
                <div
                    v-else-if="membershipObj.permissions.members"
                    class="d-flex text-body-2 ml-4 align-center flex-column"
                >
                    <v-btn
                        to="/account/manage-members"
                        color="on-secondary"
                        variant="tonal"
                        size="small"
                        nuxt
                    >
                        Manage Team
                    </v-btn>
                </div>
                <div
                    class="d-flex text-body-2 ml-4 align-center flex-column"
                >
                    <div>{{ membershipObj.descriptor }}</div>
                    <v-chip
                        v-if="membershipObj.roleName"
                        size="x-small"
                    >
                        {{ membershipObj.roleName }}
                    </v-chip>
                </div>
            </div>
        </v-container>
    </v-toolbar>
</template>

<script lang="ts" setup>
    import { useCurrentTheme } from '~/composables';
    import type { Membership } from '~/types';
    import { mdiAccountGroup } from '@mdi/js';

    const props = withDefaults(
        defineProps<{
            membershipObj: Membership;
            isReseller: boolean
        }>(),
        {
            isReseller: false
        }
    );

    const useCurrentThemeObj = useCurrentTheme();
    
    const style = computed<Record<string, string>>(() => {
        let { primaryColor, textColor } = props.membershipObj.brand;
        let color: string, backgroundColor: string;

        if (useCurrentThemeObj.themeIsDark.value) {
            color = textColor || 'rgb(var(--v-theme-accent-lighten-2))';
            backgroundColor = primaryColor || 'rgb(var(--v-theme-accent-darken-2))';
        } else {
            color = textColor || 'rgb(var(--v-theme-accent-darken-2))';
            backgroundColor = primaryColor || 'rgb(var(--v-theme-accent-lighten-2))';
        }

        return {
            color,
            backgroundColor
        };
    });
</script>

<style lang="scss" scoped>
.v-avatar {
    img {
        width: 80%;
        height: auto;
    }
}
</style>
